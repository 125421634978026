import { Controller } from "stimulus";
// import Chart from "chart.js/auto";

// Pastel colors
const colorPalette = [
  '#BAE1FF', // Pastel Blue
  '#FFC3BA', // Pastel Red
  '#FF66B2', // Pastel Magenta
  '#D9BAFF', // Pastel Lavender
  '#B3E5FF', // Pastel Cyan
  '#FFCCBA', // Pastel Peach
  '#FFB3BA', // Pastel Pink
  '#FFDFBA', // Pastel Orange
  '#FFFBBA', // Pastel Yellow
  '#BAFFB3', // Pastel Green
];

export default class extends Controller {
  currentColorIndex = 0; // Initialize a color index

  connect() {
    console.log("Chart Controller connected");

    // Access data-* attributes directly
    const xAxisData = JSON.parse(this.data.get("x-axis-data")); // Changed to match HTML attribute
    const yAxisDataSets = JSON.parse(this.data.get("y-axis-data-sets")); // Changed to match HTML attribute
    const xAxisLabel = this.data.get("x-axis-label") || 'X-Axis'; // Use camelCase for data-chart-x-axis-label
    const yAxisLabel = this.data.get("y-axis-label") || 'Y-Axis'; // Use camelCase for data-chart-y-axis-label
    
    console.log("X-Axis Data:", xAxisData); // Debugging
    console.log("Y-Axis Data Sets:", yAxisDataSets); // Debugging

    // Check if data is properly retrieved
    if (!xAxisData || !yAxisDataSets) {
      console.error("X-Axis data or Y-Axis datasets are undefined");
      return;
    }

    // Prepare datasets for the chart
    const datasets = Object.keys(yAxisDataSets).map(dataSetKey => {
      const color = this.getRandomColor(); // Use your predefined function to get a color
      return {
        label: dataSetKey, // Label for each dataset
        data: xAxisData.map(xValue => yAxisDataSets[dataSetKey][xValue] || 0), // Data for each x-axis value
        fill: false,
        borderColor: color,
        backgroundColor: color, // Ensure background color matches for filling
        tension: 0.1
      };
    });

    // Create the chart
    const ctx = this.element.getContext('2d');
    new Chart(ctx, {
      type: 'line',
      data: {
        labels: xAxisData,
        datasets: datasets
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            labels: {
              usePointStyle: true, // Use dots for legend items
              pointStyle: 'circle', // Specify the shape of the point
              pointRadius: 2, // Set the size of the dots (adjust as necessary)
            }
          }
        },
        scales: {
          x: {
            title: {
              display: true,
              text: xAxisLabel // Use the custom label or fallback
            }
          },
          y: {
            title: {
              display: true,
              text: yAxisLabel // Use the custom label or fallback
            }
          }
        }
      }
    });
  }

  getRandomColor() {
    const color = colorPalette[this.currentColorIndex]; // Get the color from the palette
    this.currentColorIndex = (this.currentColorIndex + 1) % colorPalette.length; // Increment the index
    return color;
  }
}
