// app/javascript/controllers/sortable_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    // Initial sort setup
    console.log("Sortable controller connected");
    hookupSort();

    // Set up a MutationObserver to watch for child list changes (e.g., new items appended)
    this.observer = new MutationObserver(() => {
      console.log("MutationObserver detected changes");
      hookupSort();
    });

    this.observer.observe(this.element, { childList: true });
  }

  disconnect() {
    // Clean up the observer when the controller is disconnected
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}

// Define hookupSort and make it globally accessible by attaching to window
window.hookupSort = function() {
  $(".sortable").sortable({
    update: function(e, ui) {
      $.ajax({
        url: $(this).data("url"),
        type: "PATCH",
        data: $(this).sortable('serialize'),
      });
    },
    items: ".sortable-item",
    handle: '.handle',
    placeholder: "sortable-placeholder"
  });
  
  $(".collection").droppable({
    accept: ".sortable-job-item",
    tolerance: "pointer",
    classes: {
      "ui-droppable-hover": "droppable-hover"
    },
    drop: function(event, ui) {
      console.log(ui.draggable);
      var source = $(ui.draggable).data("job-id");
      var target = $(this).data("job-id");
      var template = $(this).data("workspace-templates") == true;
      console.log("dropped " + source + " on " + target + " with is_template of " + template);
      
      $.ajax({
        url: "/jobs/" + source,
        type: "PATCH",
        data: {job: {parent_id: target, is_template: template, move_to: "top"}},
      });
      
      // Update badges
      updateBadges($(this), $(ui.draggable));
    }
  });

  // Initialize badge visibility on load
  $('.collection > .badge').each(function(i) {
    let count = Number(this.innerHTML);
    count === 0 ? $(this).hide() : $(this).show();
  });
};

// Helper function to update badge counts
const updateBadges = (destinationElement, sourceElement) => {
  let destinationBadge = destinationElement.find(".badge");
  let count = Number(destinationBadge[0].innerHTML) + 1;
  destinationBadge[0].innerHTML = count;
  
  // Show or hide the destination badge based on count
  count === 0 ? destinationBadge.hide() : destinationBadge.show();

  let sourceBadge = $(".collection.active").find(".badge");
  count = Number(sourceBadge[0].innerHTML) - 1;
  sourceBadge[0].innerHTML = count;
  
  // Show or hide the source badge based on count
  count === 0 ? sourceBadge.hide() : sourceBadge.show();

  // Remove draggable after successful drop
  sourceElement.remove();
};
  
// Add event listeners
document.addEventListener("turbo:load", () => {
  hookupSort();
});