import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    console.log("Initializing modal controller")
  }

  connect() {
    // see https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this;
    document.addEventListener('keydown', this.closeOnEscape.bind(this));
  }

  disconnect() {
    console.log("Disconnecting modal controller...")
    document.removeEventListener('keydown', this.closeOnEscape.bind(this));
  }

  closeOnEscape(event) {
    if (event.key === "Escape") {
      this.close();
    }
  }
  
  close(event) {
    console.log("Closing modal based on input")
    $('#modal').modal('hide')

    // hack: sometimes the modal command above doesn't work
    $('#modal').hide()
    $(".modal-backdrop").hide()
  }

}
