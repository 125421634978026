$(document).ready(() => {
  $(document).on('ajax:complete', '.work_log_form', function (event) {
    let details = event.detail[0]
    if (details.status == 200) {
      resetForm(event.target)
    } else {
      console.log("Error: work log creation failed")
    }
  });

  function resetForm(form) {
    form.reset();
    $($(form).find('textarea')[0]).trigger('input');
  }
});
