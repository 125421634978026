/* eslint no-console:0 */

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("stimulus-controllers")

import "@hotwired/turbo-rails"

import "bootstrap"
import "../stylesheets/application"

import "./drawer-right"
import "./extensions"
import "./job-search"
import "./job_actions"
import "./job_form"
import "./horizontal_scroll"
import "./modal"
import "./nav-tabs"
import "./work_logs_form"
import "./localize_time"
import "./address_autocomplete"
import "./judo"
// import "../controllers/turbo_custom_actions";

require("jquery-ui/ui/widget")
require("jquery-ui/ui/widgets/sortable")
require("jquery-ui/ui/widgets/droppable")

require('material-design-lite/material')

require('jquery-ui/ui/effects/effect-blind')
require("semantic-ui-api/api.min.js")
require("semantic-ui-search/search.min.js")


import { StreamActions } from "@hotwired/turbo"

// Trying to turn off turbo on links everywhere due to broken streams after redirect
// It's off for links in the application.html body tag
// Turbo.setFormMode("off");

StreamActions.toast = function() {
  const text = this.templateContent.textContent
  $('#toast')[0].toast.showToast(text)
}

// Register a custom action called `set_value`
StreamActions.set_value = function() {
  const targetId = this.getAttribute("target");
  const content = this.templateContent.textContent.trim();
  const targetElement = document.getElementById(targetId);

  // Check if the targetElement exists and is not focused
  if (targetElement) {
    if (targetElement !== document.activeElement) {
      targetElement.value = content; // Set the new value of the target element (e.g., textarea)
    } else {
      $('#toast')[0].toast.showToast("The item you're editing has been updated by another user. Please refresh the page to see the changes.")
    }
  }
};

// Register a custom action called `set_data_attribute`
Turbo.StreamActions.set_data_attribute = function() {
  // Here, `this` refers to the Turbo Stream action context
  const targetId = this.getAttribute("target");
  const dataAttribute = this.getAttribute("data-attribute");
  const content = this.getAttribute("content");

  console.log("Target ID: " + targetId, "Data Attribute: " + dataAttribute, "Content: " + content);

  const targetElement = document.getElementById(targetId);

  // Check if the targetElement exists
  if (targetElement) {
    targetElement.setAttribute(dataAttribute, content); // Set the new data attribute value
  } else {
    console.log("Target element not found for set_data_attribute");
  }
};

document.addEventListener("turbo:before-stream-render", (event) => {
  const turboStreamElement = event.target;
  // console.log("Turbo Stream command received:", event);
  // console.log("Action:", event.target.action);
  // console.log("Target:", event.target.target);

  const action = event.target.action;
  const newContent = turboStreamElement.querySelector('template').content.firstElementChild;
  if (newContent) {
    const uniqueId = newContent.getAttribute('id');
    console.log("Unique ID: " + uniqueId);

    if (action === 'set_value') {
      const targetElement = document.getElementById(target);
      if (targetElement) {
        targetElement.value = content; // Set the new value of the textarea
        event.preventDefault(); // Prevent the default rendering behavior
      }
    }
  
    if (action === 'replace') return;
    if (uniqueId) {
      const existingElement = document.getElementById(uniqueId);

      if (existingElement) {
        // Prevent duplicate append
        console.log("Preventing duplicate " + action);
        event.preventDefault();
      }
    }
  }
});

window.jQuery = $;
window.$ = $;

document.addEventListener('DOMContentLoaded', function () {
  const tableElement = document.querySelector('.mdl-data-table');
  if (tableElement) {
    new Tablesort(tableElement);
  }
});

document.addEventListener("turbo:load", () => {
  const fancyOptions = {};
  Fancybox.bind("[data-fancybox]", fancyOptions);
});