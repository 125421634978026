import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Onboarding button controller connected");
  }

  disconnect() {
  }

  show() {
    // Show the onboarding card (id="onboarding_container")
    const element = document.getElementById("onboarding_container");
    if (element) {
      element.classList.remove("hidden");
      console.debug("Opened onboarding card");
    }
  }

  close() {
    // Close the onboarding card
    this.element.classList.add("hidden");
    console.debug("Closed onboarding card");
  }

  scroll() {
    console.log("Scrolling to onboarding card");
    const element = document.getElementById("onboarding_frame");
    element.scrollIntoView({behavior: 'smooth', alignto: 'top'});
  }
}