import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    console.log("Initializing list-redirect controller")
  }

  // Connect the controller
  connect() {
    // Add an event listener for the click event
    console.log("List redirect controller connected.");
  }

  refresh(event) {
    // Prevent default behavior if necessary
    event.preventDefault();

    // Get the collection ID from the data attribute
    const collectionId = this.element.dataset.collectionId;
    console.log(`Refreshing item list with collection ID: ${collectionId}`);

    // Find the parent item-list controller
    const itemListController = this.getItemListController(collectionId);

    if (itemListController) {
      // Call the refresh method on the item-list controller and pass the collection ID
      itemListController.refresh(event);
    }
  }

  getItemListController(collectionId) {
    // Search the entire DOM for an element with the matching data-item-list-container-id
    const itemListElement = document.querySelector(`[data-controller='item-list'][data-item-list-container-id='${collectionId}']`);
    const controller = itemListElement ? itemListElement.itemList : null;
    console.log("Item list controller:", controller);
    return controller;
  }
}
