import { Controller } from "stimulus"

export default class extends Controller {

  static values = {
    containerId: String,
    priority: String,
    state: String
  }

  initialize() {
    console.log("Initializing item-list controller")
  }

  setupLazyload() {
    // see https://medium.com/@ryanfinni/the-intersection-observer-api-practical-examples-7844dfa429e9
    function handleIntersection(entries) {
      entries.map((entry) => {
        if (entry.isIntersecting) {
          console.log("Intersecting item!")
          entry.target.item.refresh()
          observer.unobserve(entry.target);
        }
      });
    }

    var lazyLoadItems = document.querySelectorAll('.lazyload');
    const observer = new IntersectionObserver(handleIntersection);
    lazyLoadItems.forEach(item => observer.observe(item));
  }

  connect() {
    // see https://leastbad.com/stimulus-power-move
    // this.element[this.identifier] = this;
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this;
    console.log("Connecting item-list")

    this.setupLazyload();
  }

  dirty(event) {
    return;
    console.log("I'm a dirty item-list!")
    refresh(event);
  }

  refresh(event) {
    console.log("In item-list#refresh")
    var that = this;
    // var tab_id = (event.target.closest("a").href).split("#")[1];
    // console.log("Tab id: " + tab_id)
    // var div = $(this.element).find('#' + tab_id + ' .jobs-table')[0];
    var inbox = event.currentTarget.dataset.itemListInbox !== undefined
    setTimeout(function() {
      var div = $(that.element).find('div.jobs-table.auto-refresh:visible')[0];
      console.log(div);

      var type = div.getAttribute("data-collection-type");
      var url = `/jobs/${that.data.get('container-id')}/refresh?type=${type}&inbox=${inbox}`;
      if (that.data.get('priority')) {
        url = url + '&priority=' + that.data.get('priority');
      }
      if (that.data.get('state')) {
        url = url + '&state=' + that.data.get('state');
      }

      $.ajax({
        url: url,
        success: function(result) {
          $(div).html(result);
          hookupJavascript();
          that.setupLazyload();
      }});
    }, 5);
  }

}
