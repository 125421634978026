import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

// Define the plugin once outside the controller class
(function($, window, document) {
  const pluginName = "textareaAutoSize";

  function Plugin(element) {
    this.element = element;
    this.$element = $(element);
    this.init();
  }

  Plugin.prototype = {
    init: function() {
      const paddingAndBorders = parseInt(this.$element.css("paddingBottom")) +
                                parseInt(this.$element.css("paddingTop")) +
                                parseInt(this.$element.css("borderTopWidth")) +
                                parseInt(this.$element.css("borderBottomWidth")) || 0;

      // Set the height if there's existing content
      if (this.element.value.trim().length > 0) {
        this.$element.height(this.element.scrollHeight - paddingAndBorders);
      }

      // Adjust the height on input or keyup events
      this.$element.on("input keyup", function() {
        const $window = $(window);
        const scrollTop = $window.scrollTop();

        $(this).height(0).height(this.scrollHeight - paddingAndBorders);
        $window.scrollTop(scrollTop);
      });
    }
  };

  $.fn[pluginName] = function() {
    return this.each(function() {
      if (!$.data(this, "plugin_" + pluginName)) {
        $.data(this, "plugin_" + pluginName, new Plugin(this));
      }
    });
  };
})(jQuery, window, document);

export default class extends Controller {
    connect() {
      this.element.classList.add('textarea-autosize');   // Add the 'textarea-autosize' class
      $(this.element).textareaAutoSize();                // Initialize the plugin for this element
  
      // Trigger initial input to adjust height
      setTimeout(() => {
        $(this.element).trigger('input');
      }, 500);
  
      // Add event listener for form reset
      this.form = this.element.closest("form");
      if (this.form) {
        this.resetHandler = this.resetTextareaHeight.bind(this);
        this.form.addEventListener("reset", this.resetHandler);
      }
    }
  
    disconnect() {
      // Remove the reset event listener when the controller disconnects
      if (this.form) {
        this.form.removeEventListener("reset", this.resetHandler);
      }
    }
  
    resetTextareaHeight() {
      $(this.element).height("auto");  // Reset height to auto (minimal)
    }
  }