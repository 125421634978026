import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = []

  connect() {
    console.log("AppInstallationController connected");
  }

  install(event) {
    event.preventDefault();

    const button = event.currentTarget;
    const organizationId = this.element.dataset.organizationId;
    const applicationId = this.element.dataset.applicationId;

    // Add 'non-clickable' class and update the UI
    button.classList.add("non-clickable");
    button.innerHTML = `
      <div class="mdl-spinner mdl-js-spinner is-active mr-2" style="vertical-align:middle;"></div>
      App installing
    `;
    // Re-upgrade the MDL components after inserting spinner HTML
    componentHandler.upgradeAllRegistered();

    Rails.ajax({
      url: "/app_installations",
      type: "POST",
      data: new URLSearchParams({
        "app_installation[organization_id]": organizationId,
        "app_installation[application_id]": applicationId
      }).toString(),
      success: (response) => {
        console.log("App installation successful:", response);
        // Optionally, you can redirect or update the page here after successful installation
      },
      error: (response) => {
        console.error("App installation failed:", response);
        // Remove 'non-clickable' class and reset the button in case of error
        button.classList.remove("non-clickable");
        button.innerHTML = `
          <i class="mr-2 material-icons" style="vertical-align:middle">backup</i>
          Install this app
        `;
        componentHandler.upgradeAllRegistered();
      }
    });
  }
}
