import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (!this.element.id) {
      console.error("Button element must have an ID");
      return;
    }

    if (typeof componentHandler === "undefined") {
      console.error("Material Design Lite componentHandler not found");
      return;
    }

    // Find and upgrade the menu element once
    this.menu = document.querySelector(`.mdl-menu[for="${this.element.id}"]`);
    if (!this.menu) {
      console.error(`Menu for "${this.element.id}" not found`);
      return;
    }

    componentHandler.upgradeElement(this.menu);

    // Select the parent node as the menu container
    this.menuContainer = this.menu.parentNode;
    if (!this.menuContainer.classList.contains("mdl-menu__container")) {
      console.error("Menu container not found - expected direct parent element");
      return;
    }

    // Append the menu container to the body and style it
    const itemPreview = document.querySelector("#item-preview");
    const pageContent = document.querySelector("#page-content");

    if (itemPreview && itemPreview.contains(this.element)) {
      itemPreview.appendChild(this.menuContainer);
    } else {
      pageContent.appendChild(this.menuContainer);
    }
    this.menuContainer.style.position = "absolute";
    this.menuContainer.style.zIndex = "100";

    // Add event listener for repositioning the menu
    this.element.addEventListener("click", this.handleClick.bind(this));

    // Initialize containers and scroll handling
    this.contentContainer = pageContent;
    this.overlayDrawer = itemPreview;

    // Set up a MutationObserver to watch for changes in the overlay drawer's visibility
    this.observer = new MutationObserver(this.bindScrollEvents.bind(this));
    this.observer.observe(this.overlayDrawer, { attributes: true, attributeFilter: ["class"] });
  }

  disconnect() {
    // Remove event listeners on disconnect
    this.unbindScrollEvents();
    if (this.observer) this.observer.disconnect();
  }

  bindScrollEvents() {
    // Unbind current scroll events to reset
    this.unbindScrollEvents();

    const isOverlayVisible = this.overlayDrawer.classList.contains("active");
    this.activeScrollContainer = isOverlayVisible ? this.overlayDrawer : this.contentContainer;

    if (this.activeScrollContainer) {
      this.boundHandleScroll = this.handleScroll.bind(this);
      this.activeScrollContainer.addEventListener("scroll", this.boundHandleScroll, { passive: true });
    }
  }

  unbindScrollEvents() {
    if (this.activeScrollContainer && this.boundHandleScroll) {
      this.activeScrollContainer.removeEventListener("scroll", this.boundHandleScroll);
    }
  }

  handleScroll() {
    if (!this.menuContainer || !this.menuContainer.classList.contains("is-visible")) return;
    this.repositionMenu();
  }

  handleClick() {
    this.bindScrollEvents();

    if (!this.menuContainer) return;
    // Use setTimeout to delay repositioning the menu
    setTimeout(() => {
      this.repositionMenu();
    }, 100);
  }
 
  repositionMenu() {
    if (!this.menuContainer || !this.menuContainer.classList.contains("is-visible")) return;
  
    const buttonRect = this.element.getBoundingClientRect();
    const buttonTop = buttonRect.top; // Get the top position relative to the viewport
    const buttonLeft = buttonRect.left; // Get the left position relative to the viewport
    const buttonWidth = buttonRect.width;
    const menuWidth = this.menuContainer.offsetWidth;
    const viewportWidth = window.innerWidth;
  
    // Determine the parent container to use for positioning
    const menuParent = this.menuContainer.parentNode;
    const parentRect = menuParent.getBoundingClientRect();
  
    // Calculate offsets based on the parent container
    const topOffset = buttonTop - parentRect.top + buttonRect.height; // No scroll here
    const leftOffset = buttonLeft - parentRect.left;
  
    // Set vertical position below the button
    this.menuContainer.style.top = `${topOffset + menuParent.scrollTop}px`; // Add scroll of the parent container
  
    const isRightAligned = this.menu.classList.contains("mdl-menu--bottom-right");
  
    // Horizontal positioning with overflow handling
    if (isRightAligned) {
      const rightAlignedLeft = leftOffset + buttonWidth - menuWidth;
      this.menuContainer.style.left = rightAlignedLeft < 0 ? "0px" : `${rightAlignedLeft}px`;
    } else {
      this.menuContainer.style.left = 
        leftOffset + menuWidth > viewportWidth 
          ? `${leftOffset + buttonWidth - menuWidth}px` 
          : `${leftOffset}px`;
    }
  }

}
