import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["addItemCTA", "itemForm", "textarea", "subitemsOption", "form", "commandKeySpan"];

  connect() {
    // Expose this controller instance for external use
    this.element[this.identifier] = this;
    console.log(`JobForm connected with parent ID: ${this.parentId}`);
    this.updateCommandKey();
    this.formElement.addEventListener('keydown', this.handleKeydown.bind(this));
    this.checkSubitems(); // Initialize subitem visibility
  }

  disconnect() {
    this.formElement.removeEventListener('keydown', this.handleKeydown.bind(this));
  }

  // Cached variables
  get parentId() {
    return this.data.get("parent-id");
  }

  get formElement() {
    return this.formTarget;
  }

  get textareaElement() {
    return this.textareaTarget;
  }

  showItemForm() {
    if (!this.hasAddItemCTATarget) {
      console.warn("addItemCTA target is missing. Skipping showItemForm.");
      return;
    }
    console.log("Showing item form");
    this.addItemCTATarget.style.display = "none";
    this.itemFormTarget.style.display = "block";
    this.textareaElement.focus();
  }

  hideItemForm() {
    console.log("Hiding item form");
    this.addItemCTATarget.style.display = "block";
    this.itemFormTarget.style.display = "none";
    this.resetForm();
  }

  resetForm() {
    console.log("Resetting form");
    this.formTarget.reset();
    this.textareaElement.dispatchEvent(new Event("input"));
    this.checkSubitems();
    // Hide additional fields or other UI elements if needed
    const additionalFields = this.element.querySelectorAll(".collapse.show");
    additionalFields.forEach(field => field.classList.remove("show"));
  }

  checkSubitems() {
    const content = this.textareaElement.value;
    const hasSubitems = content.split("\n").length > 2;
    this.subitemsOptionTarget.style.display = hasSubitems ? "block" : "none";
  }

  postSuccess() {
    console.log("Post success");
    this.resetForm();
    showToast("Added");
    // hookupJavascript(); // Assuming this initializes additional JavaScript logic
    this.showItemForm(); // Keeps the form visible for further edits
  }

  updateCommandKey() {
    if (!this.hasCommandKeySpanTarget) {
      return; // Exit gracefully if the target is missing
    }

    const isMac = navigator.platform.indexOf('Mac') !== -1;
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);

    if (isMobile) {
      // Hide the entire span on mobile
      this.commandKeySpanTarget.style.display = 'none';
    } else {
      // Show the span and adjust text based on Mac or PC
      this.commandKeySpanTarget.style.display = 'inline'; // Ensure it is visible on non-mobile
      if (isMac) {
        this.commandKeySpanTarget.innerHTML = '⌘-enter submits';
      } else {
        this.commandKeySpanTarget.innerHTML = 'Ctrl-Enter submits';
      }
    }
  }

  handleKeydown(event) {
    // Submit the form on Command-Enter (Mac) or Ctrl-Enter (PC)
    if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
      console.log("Submitting form with Command/Ctrl + Enter");
      this.formElement.requestSubmit();
    }
  }

  switchParent(event) {
    event.preventDefault()
    let target = event.currentTarget
    let newParentId = target.dataset.newParentId
    let parentInput = this.element.querySelector("#job_parent_id")
    let parentTitle = target.parentElement.querySelector(".parent-title")
    let oldParentId = this.data.get('parent-id')

    if (parentInput.value == oldParentId) {
      parentInput.value = newParentId
      parentTitle.innerText = "current folder."
      target.innerText = `Add to ${this.data.get('parent-name')} instead`
    } else {
      parentInput.value = oldParentId
      parentTitle.innerText = this.data.get('parent-name') + "."
      target.innerText = "Add to current folder instead"
    }
  }
}


// refresh() {
//   var itemId = this.data.get("id");
//   var partial = this.data.get("partial");
//   var that = this;
//   $.ajax({
//     url: '/jobs/' + itemId + '?partial=' + partial,
//     success: function(result) {
//       console.log($(this.element));
//       $(that.element).replaceWith(result);
//       hookupJavascript();
//   }});
//}

//   dispatchDirty() {
//   return;
//   var itemId = this.data.get("parent-id");
//   const event = document.createEvent("CustomEvent")
//   event.initCustomEvent("item-dirty", true, true, { item: itemId })
//   this.element.dispatchEvent(event)
// }

// dirty() {
//   return;
//   console.log("Job form is dirty!")
//   this.refresh();
// }

