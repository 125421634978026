import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Onboarding controller connected");
    this.highlightCard()
  }

  disconnect() {
    // Remove the highlight style if the target element exists
    this.close()
  }

  show() {
    // Show the onboarding card (id="onboarding_container")
    const element = document.getElementById("onboarding_container");

    if (element) {
      element.classList.remove("hidden");
      console.debug("Opened onboarding card");
    }
    this.highlightCard();
  }

  close() {
    // Close the onboarding card
    this.element.classList.add("hidden");
    console.debug("Closed onboarding card");
    this.removeHighlight();
  }

  highlightCard() {
    // Access data-onboarding-highlight-card directly using JavaScript
    this.highlightCard = this.element.dataset.onboardingHighlightCard;
    console.debug("Highlight card data attribute (using dataset):", this.highlightCard);
        
    if (this.highlightCard) {
      // Find the element and apply the highlight style
      this.targetElement = document.querySelector(this.highlightCard);
      console.debug("Target element found:", this.targetElement);

      if (this.targetElement) {
        this.targetElement.classList.add("highlight-stripe-yellow");
        console.debug("Applied highlight-stripe-yellow to target element");
      } else {
        console.warn("No element found for selector:", this.highlightCard);
      }
    } else {
      console.warn("No highlight-card attribute provided");
    }
  }

  removeHighlight() {
    if (this.targetElement) {
      this.targetElement.classList.remove("highlight-stripe-yellow");
      console.debug("Removed highlight-stripe-yellow from target element");
    } else {
      console.debug("No target element to remove highlight from");
    }
  }

  scrollTo() {
    const element = document.getElementById("onboarding_container");
    element.scrollIntoView({behavior: 'smooth', alignto: 'top'});
  }

  assistantSuggest() {
    const assistantTextarea = $("#assistant-chat textarea")[0];
    assistantTextarea.value = this.element.dataset.onboardingAssistantSuggestion;
    assistantTextarea.focus();
    $(assistantTextarea).trigger('input');
  }

}



  // async loadCard(position) {
  //   try {
  //     const url = `/onboarding/${this.contextValue}/${position}`
  //     console.log("Fetching from URL:", url)

  //     const response = await fetch(url, {
  //       headers: { 'Accept': 'text/vnd.turbo-stream.html' }
  //     })

  //     if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)

  //     // Use the response text with Turbo.renderStreamMessage
  //     const turboStreamContent = await response.text()
  //     Turbo.renderStreamMessage(turboStreamContent)  // Process Turbo Stream commands

  //     const cardCount = parseInt(this.element.dataset.onboardingCardCount, 10)
  //     this.updateButtonStates(position, cardCount)

  //     console.log("Content refreshed successfully with Turbo Stream")
  //     this.updateButtonStates(position)
  //   } catch (error) {
  //     console.error("Error loading card:", error)
  //   }
  // }
