// app/javascript/controllers/mdl_card_title_width_controller.js

// credit to chatGPT for this snippet
// https://chatgpt.com/share/aa5c1625-6315-47d4-81bf-9369c240c158
// This controller adjusts the width of the title of an MDL card based on the width of the card and the card menu.
// It ensures that the title does not overlap with the menu.
// Usage: <div data-controller="mdl-card-title-width">...</div>
// The controller should be used on a div that contains the MDL card.
// The div should have a child element with the class `mdl-card__title` and another child element with the class `mdl-card__menu`.

import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.log("Connecting MDL card title width controller...");

    // Initialize the observer
    this.observer = new MutationObserver(() => {
      this.checkElements();
    });

    // Start observing the DOM for changes
    this.observer.observe(this.element, {
      childList: true, // Observe direct children
      subtree: true, // Observe all descendants
      attributes: true // Observe attribute changes
    });

    // Check for initial elements
    this.checkElements();
    
    // Listen for window resize events
    window.addEventListener("resize", this.adjustTitleWidth.bind(this));
  }

  disconnect() {
    window.removeEventListener("resize", this.adjustTitleWidth.bind(this));
    
    // Disconnect the observer when the controller is disconnected
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  checkElements() {
    this.titleElement = this.element.querySelector(".mdl-card__title");
    this.menuElement = this.element.querySelector(".mdl-card__menu");

    if (this.titleElement && this.menuElement) {
      this.adjustTitleWidth();
    }
  }

  adjustTitleWidth() {
    const cardMenuWidth = this.menuElement.offsetWidth;
    const cardWidth = this.element.offsetWidth;
    const newTitleMaxWidth = cardWidth - cardMenuWidth - 16; // 8px padding on each side
    // console.log(`Card width: ${cardWidth}, Menu width: ${cardMenuWidth}, New title max width: ${newTitleMaxWidth}`);

    this.titleElement.style.maxWidth = `${newTitleMaxWidth}px`;
  }
}
